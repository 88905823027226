import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(1);
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 20px;
      font-size: var(--fz-lg);

      span {
        font-weight: 900;
      }

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }

    a {
      color: var(--green);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      margin-left: 12px;
    }
  }
`;

const Publications = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    <div>
      Kazemabad, M. J. E., Toni, S. A., ... , <span>Noroozi, M.</span>, ... &amp; Deravi, N. (2022).
      Pharmacotherapeutic potential of pomegranate in age-related neurological disorders. Frontiers
      in Aging Neuroscience, 14.{' '}
      <a href="https://www.frontiersin.org/articles/10.3389/fnagi.2022.955735/full">DOI</a>
    </div>,

    <div>
      Moravvej, S. V., ... , <span>Noroozi, M.</span>, ... &amp; Nahavandi, S. (2021-2024).
      Automatic answer selection: An efficient approach considering a solution for pre-training and
      imbalanced classification. (Under revision)
      <a href="https://drive.google.com/file/d/10kJonFWuVFlXMCfJO132Me866-0D2Tfs/view?usp=drivesdk">
        Link
      </a>
    </div>,
    <div>
      <span>Noroozi, M.</span> ,... &amp; Deravi, N. (2022-2024). Machine learning for the diagnosis
      of different types of dementia: A Review of Literature. (Under revision){' '}
      <a href="https://drive.google.com/file/d/1Fg7iRLnG9jJ0FpJzUUD29IR5Fid2dOu9/view?usp=drive_link">
        Link
      </a>
    </div>,
    <div>
      <span>Noroozi, M.</span> , ... &amp; Mayeli, M. (2022-2024). The association between
      hippocampal volumes and neuropsychiatric assessments in healthy population. (Under revision){' '}
      <a href="https://drive.google.com/file/d/1gUvOwkjLb-IjUXwfP2BtyUpMhQkxS8GS/view">Link</a>
    </div>,
  ];

  return (
    <StyledAboutSection id="publications" ref={revealContainer}>
      <h2 className="numbered-heading">Selected Publications</h2>

      <div className="inner">
        <StyledText>
          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default Publications;
