import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(1);
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 20px;
      font-size: var(--fz-lg);

      span {
        font-weight: 900;
      }

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }

    a {
      color: var(--green);
      font-family: var(--font-mono);
      font-size: var(--fz-xs);
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      margin-left: 12px;
    }
  }
`;

const References = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <span style={{ width: '50%', display: 'flex' }}>Dr. Hamid Reza Marateb</span> University of
        Isfahan, Isfahan, Iran
      </div>
      <a href="https://scholar.google.com/citations?user=e9IuAXwAAAAJ&amp;hl=en&amp;oi=ao">Link</a>
    </div>,

    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <span style={{ width: '50%', display: 'flex' }}>Dr. Hossein Arabi</span> University of
        Geneva, Geneva , Switzerland
      </div>
      <a href="https://scholar.google.com/citations?user=XtwYNycAAAAJ&amp;hl=en&amp;oi=ao">Link</a>
    </div>,

    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <span style={{ width: '50%', display: 'flex' }}>Prof. Alireza Karimian</span> University of
        Isfahan, Isfahan, Iran
      </div>
      <a href="https://scholar.google.com/citations?user=MMwGJSUAAAAJ&amp;hl=en&amp;oi=ao">Link</a>
    </div>,

    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <span style={{ width: '50%', display: 'flex' }}>Dr. Rahele Kafieh</span> Durham University,
        Durham, England
      </div>
      <a href="https://scholar.google.com/citations?user=5RJCya4AAAAJ&amp;hl=en&amp;oi=ao">Link</a>
    </div>,

    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', width: '100%' }}>
        <span style={{ width: '50%', display: 'flex' }}>Dr. Mohammadreza Yazdchi</span> University
        of Isfahan, Isfahan, Iran
      </div>
      <a href="https://scholar.google.com/citations?user=bjoizUMAAAAJ&amp;hl=en&amp;oi=ao">Link</a>
    </div>,
  ];

  return (
    <StyledAboutSection id="publications" ref={revealContainer}>
      <h2 className="numbered-heading">References</h2>

      <div className="inner">
        <StyledText>
          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default References;
