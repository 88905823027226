import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';
import ResearchInterests from '../components/sections/research-interest';
import Skills from '../components/sections/skills';
import Study from '../components/sections/study';
import Research from '../components/sections/research';
import Teaching from '../components/sections/teaching';
import Publications from '../components/sections/publications';
import UniversityProjects from '../components/sections/university-projects';
import References from '../components/sections/references';
import International from '../components/sections/international';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
      <Hero />
      <About />
      <ResearchInterests />
      <Skills />
      <Study />
      <Jobs />
      <Research />
      <Teaching />
      <Publications />
      <International />
      <UniversityProjects />
      <References />
      {/* <Featured /> */}
      {/* <Projects /> */}
      <Contact />
    </StyledMainContainer>
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
