import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(1);
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 20px;
      font-size: var(--fz-lg);

      span {
        font-weight: 900;
      }

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const Skills = () => {
  const revealContainer = useRef(null);

  useEffect(() => {
    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    <div>
      Deep Learning: <span>LSTM, GAN, U-NET, CNN, RNN, …</span>
    </div>,

    <div>
      Machine Learning: <span>Logistic Regression, Clustering, Linear Regression, ...</span>
    </div>,

    <div>
      AI Packages: <span>TensorFlow, Keras, OpenCV, …</span>
    </div>,

    <div>
      Softwares & Programming Languages: <span>Python, Matlab, SPSS, C#/C++, R, Stata, Altium Designer, Simulink, PSpice</span>
    </div>,
    
    <div>
      Typesetting: <span>Microsoft Office, LaTeX</span>
    </div>,

    <div>
      Social Skills:{' '}
      <span>
        Time Management, Team Working, Leadership, Public Speaking, Presentation, Critical Thinking
      </span>
    </div>,
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">Skills</h2>

      <div className="inner">
        <StyledText>
          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default Skills;
